
function LoadSliderScripts()
{
   
    (function ($) {
        /*Home Banner Slider--------------------------------------------------------------------------------*/
        $('.single-item').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: true,
            pauseOnHover: false,
            dots: false,
            fade: false,
        });


        /*Navbar on mobile--------------------------------------------------------------------------------*/
        $('#dahish').on('click', function () {
            $('div.collapse').toggleClass('ino');
            $('.navbar-toggler').toggleClass('pin');
        });
        $('.navbar-collapse ul.navbar-nav li:nth-child(2) a, .navbar-collapse ul.navbar-nav li:nth-child(4) a, .navbar-collapse ul.navbar-nav li:nth-child(5) a').on('click', function () {
            $("div.collapse").removeClass("ino");
			$(".navbar-toggler").removeClass("pin");
        });


    })(jQuery);
}
